import React from 'react';
import { isMobile } from 'react-device-detect';
import emailjs from "emailjs-com";
import Carousel from "../index-sections/Carousel"
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form
} from "reactstrap";
import apiKeys from "../../apikeys.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  var isM = ""
  if (isMobile) {
    isM = "X";
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    emailjs.sendForm(apiKeys.SERVICE_ID, apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
    .then(result => {
    alert('Votre email a ete envoyé', result.text);
    },
    error => {
    alert( 'une erreur est survenue',error.text)
    })
    }

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      {/* <ExamplesNavbar /> */}
      <div className="wrapper">
        {/* <LandingPageHeader /> */}
        <div className="section section-about-us" style={{ backgroundColor : '#000' }}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{ color: '#fff' }}>Gagner du temps ! <br/>Votre expert MPVI intervient désormais directement sur votre parc pour assurer vos réparations !</h2>
                <h4 className="title" style={{ color: '#fff' }}>Contactez nous : 07 67 07 22 36</h4>
                <h3 className="title" style={{ color: '#fff' }}>Avec MPVI, la maintenance de votre véhicule c’est :</h3>
                <p style={{ color: '#fff', fontWeight: 'bold' }}>Une intervention mobile rapide et efficace</p>
                <p style={{ color: '#fff' , fontWeight: 'bold' }}>Une entreprise familiale et intergénérationnel depuis plus de 15 ans</p>
                <p style={{ color: '#fff' , fontWeight: 'bold' }}>Une équipe de professionnels hautement qualifiée et formée à toutes les compétences nécessaires aux poids lourds</p>
                {/* <img
                  style={{width : '25%', marginRight : '30px'}}
                  alt="..."
                  src={require("assets/img/note.png").default}
                ></img> */}
              </Col>
            </Row>
             {/*<Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <p className="h5">En rejoignant DDS Groupe vous bénéficierez :</p>
                  <p>- Des certificateurs ICDL et ETS GLOBAL </p>
                  <p>- De la validation de vos actions par France Compétences</p>
                  <p>- De l'éligibilité de vos actions de formation au CPF</p>
                  <div className="hero-images-container" style={{ marginBottom: '15px'}}>
                <img
                  style={{width : '25%', marginRight : '30px'}}
                  alt="..."
                  src={require("assets/img/icdl.png").default}
                ></img>
                <img
                  style={{width : '25%', marginBottom: '15px'}}
                  alt="..."
                  src={require("assets/img/ets.png").default}
                ></img>
              </div>
              </Col>
            </Row>
            <div className="separator separator-primary" ></div>
            <div className="section-story-overview" style={{ paddingTop: '0px' }}>
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/login" + isM + ".jpg").default + ")",
                    }}
                  >
                    <p className="blockquote blockquote-info" style={{ color: '#5ce1e6', fontWeight: 'bold', borderColor : '#5ce1e6' }}>
                    Un levier commercial,
                    vos clients peuvent bénéficier de financement pour payer et suivre vos formations. Une solution simple et efficace pour développer votre chiffre d'affaires
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg3" + isM + ".jpg").default + ")",
                    }}
                  ></div>
                  
                 
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg1" + isM + ".jpg").default + ")",
                    }}
                  ></div>
                  <h3 style={{ color: '#5ce1e6', fontWeight: 'bold' }}>
                    Devenez partenaire
                  </h3>
                  <p>
                 <p>Vous souhaitez devenir formateur avec votre propre structure sans avoir à prendre en charge la certification Qualiopi ?</p>
                   <p>C’est possible ! Il vous suffit de créer votre entreprise et nous vous aidons à obtenir votre numéro d’enregistrement auprès de la DREETS.</p>
                 <p>Vous devenez formateur indépendant et collaborer avec notre organisme.</p>
                  </p>
                    <p>
                    Rejoindre DDS c'est aussi faire partie du monde de la formation, 
                    plus de 70 sources de financement existent pour vos futurs clients : pôle emploi, CPF , OPCO etc....
                  </p>
                  <ul>
                    <li>Assistance administrative</li>
                    <li>Financement accessibles</li>
                    <li>Gestionnaire attitré</li>
                    <li>Accompagnement pour la demande de déclaration d'activité</li>
                  </ul>
                  
                  
                </Col>
              </Row>
              <p className="h3" style={{ color: '#5ce1e6', fontWeight: 'bold' }}>Vous souhaitez être formateur avec DDS Groupe ?</p>
                  <p>
                  Utilisez le formulaire contact pour déterminer une date de RDV et bénéficier de plus d'informations.
                  </p>
                  <Card>
                    <CardBody>
                      <p className="codecolor h5" style={{ color: '#5ce1e6', fontWeight: 'bold' }}>Si vous voulez devenir adhérent il faudra alors :</p>
                      <p className="">1) Obtenir votre déclaration d'activité avec notre accompagnement.</p>
                      <p className="">2) Collaborer ensemble pour montez votre programme de formation.</p>
                      <p className="">3) Dispenser votre formation !</p>
                    </CardBody>
                  </Card>
            </div> */}
            <Col className="ml-auto mr-auto text-center" md="8">
            <Carousel />
            {/* <div className="text-center" style={{ justifyItems : 'center' }}>
                    <p className="h3" style={{ color: '#5ce1e6', fontWeight: 'bold', marginTop : '20px' }}>
                      Faites comme LOC'AR et devenez formateur
                    </p>
                    <video width="280" height="500" controls style={{ marginTop : '20px'}}>
                    <source src="/Videos/locar.mp4" type="video/mp4"/>
                  </video>
                  </div> */}
                {/* <p className="h3" style={{ color: '#5ce1e6', fontWeight: 'bold', marginTop : '20px' }}>N'attendez plus, <span style={{ color: '#ff66c4' }}>rejoignez nous !</span></p> */}
                {/* <p className="h4">
                  Nous mettons à disposition un système dédié spécifiquement à la gestion administrative, offrant la possibilité aux formateurs indépendants de gérer de bout en bout leur formation sans se soucier des documents et de leur conformité.
                </p> */}
              </Col>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p className="h3" style={{ color: '#fff', fontWeight: 'bold' }}>Tout est plus simple avec MPVI :</p>
                <Row>
                <Col md="6">
                <img
                  style={{width : '20%'}}
                  alt="..."
                  src={require("assets/img/wa.png").default}
                ></img>
                <p className="h4" style={{ color: '#fff', fontWeight: 'bold' }}>Disponible 24/24h et 7/7</p>
                </Col>
               <Col md="6">
               <img
                  style={{width : '20%'}}
                  alt="..."
                  src={require("assets/img/gestu.png").default}
                ></img>
                <p className="h4" style={{ color: '#fff', fontWeight: 'bold' }}>Maintenance poids lourd</p>
                 </Col>
                </Row>

                <Row>
                <Col md="6">
                <img
                  style={{width : '20%'}}
                  alt="..."
                  src={require("assets/img/fr.png").default}
                ></img>
                <p className="h4" style={{ color: '#fff', fontWeight: 'bold' }}>Partout en Île-de-France <br/> et <br/>limitrophe</p>
                </Col>
                <Col md="6">
                <img
                  style={{width : '20%'}}
                  alt="..."
                  src={require("assets/img/meca.png").default}
                ></img>
                <p className="h4" style={{ color: '#fff', fontWeight: 'bold' }}>Technicien confirmé</p>
                </Col>
                </Row>
              </Col>
          </Container>
        </div>
        <div className="section section-team ">
          <Container>
            <h2 className="title">Qui sommes-nous ?</h2>
            <h4 className="title">Des garagistes expérimentés pour la maintenance des véhicules poids lourds et les réparations mécaniques</h4>
            <p className="">
            Spécialiste depuis 2008, MPVI assure l’entretien, la réparation mécanique ainsi que le dépannage de camions, bus, cars, remorques et véhicules utilitaires légers dans la région Île-de-France et limitrophe
            </p>
            <p className="">
            MPVI est une entreprise familiale, qui pour faciliter votre vie, intervient en se déplaçant directement sur vos sites afin de vous permettre :

            </p>
            <ul>
              <li>D'optimiser la productivité de vos véhicules</li>
              <li>De réduire les temps d’immobilisation</li>
              <li>De regrouper les réparations à réaliser sur l’ensemble de votre parc.</li>
            </ul>

            <p className="">
            En plus d’être réactive au contact de nos clients, notre équipe de professionnels vous garantit une intervention rapide et efficace.
            </p>
            <p className="blockquote">
            Sécurité, fiabilité et expertise :  MPVI est la solution à vos besoins !
              </p>
            <div className="team text-center">
            <h2 className="title">Nos services</h2>
            <p className="title">
            De la vérification à la réparation, bénéficiez d'un service complet, rapide et sûr pour vos véhicules
              </p>
              <Row className="section section-team ">
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/ava3.png").default}
                    ></img>
                    <h4 className="title text-primary">UNE OFFRE ADAPTEE POUR TOUT TYPE DE VÉHICULE</h4>
                    <p className="">Nos techniciens garagistes MPVI interviennent en mobile sur les véhicules industriels, camions, cars, bus, remorques et véhicules utilitaires légers.</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/ava2.png").default}
                    ></img>
                    <h4 className="title text-primary">DES SOLUTIONS À TOUS LES NIVEAUX DU VÉHICULE</h4>
                    <p className="c">Pour le bon fonctionnement de vos véhicules, MPVI vous propose un service complet pour chacune des opérations nécessaires : l’entretien, le freinage, le moteur, la mécanique, l’équipement hydraulique et le lavage.</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/ava1.png").default}
                    ></img>
                    <h4 className="title text-primary">UNE EXPERTISE PRÉCISE ET RAPIDE</h4>
                    <p className="">Nos experts MPVI réalisent un précontrôle technique en vérifiant les freins, le moteur ainsi que les les éléments électronique pour évaluer les réparations du poids lourd à effectuer.</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div id="contactdds" className="section section-contact-us text-center">
          <div>
            <h4 className="title">Nous sommes disponibles au 07 67 07 22 36</h4>
                <p className="h4" style={{ color: '#fff', fontWeight: 'bold' }}>Disponible 24/24h et 7/7</p>
          </div>
          <Container>
            <h2 className="title" href="#pablo" style={{ color: 'black', fontWeight: 'bold' }}>Nous contacter</h2>
            <p className="description" style={{fontWeight: 'bold' }}>Contactez-nous pour toute demande de rendez-vous ou pour la réalisation d'un devis pour une intervention mobile.</p>
            <Form className="contact-form" onSubmit={onSubmit}>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  style={{ color: '#5ce1e6'}}
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nom..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                    name="name"
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_send"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Tel..."
                    type="num"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                    name="tel"
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                    name="email"
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="text"
                    placeholder="Votre message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    style={{ backgroundColor : '#000', color: 'white', fontWeight: 'bold' }}
                    size="lg"
                    type="submit"
                  >
                    Envoyer
                  </Button>
                </div>
              </Col>
            </Row>
            </Form>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
