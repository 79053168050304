/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" style={{ backgroundColor : '#000'}}>
      <Container>
        <nav>
          <ul>
            <li>
              <a
                href="/profile-page"
                style={{ color : 'white' }}
              >
                Mention légales / CGU / Politique de confidentialité
              </a>
            </li>
            {/* <li>
              <a
                href="http://presentation.creative-tim.com?ref=nukr-dark-footer"
                target="_blank"
              >
                About Us
              </a>
            </li> */}
          </ul>
        </nav>
        <div className="copyright" id="copyright" style={{ color : 'white' }}>
          © MPVI, 2022
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
